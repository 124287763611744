// assets
import { IconUser, IconActivityHeartbeat, IconBrandBlogger, IconSettingsDollar ,IconCalendarDollar,IconTransactionDollar} from '@tabler/icons-react';

// constant
const icons = {
  IconUser,
  IconActivityHeartbeat,
  IconBrandBlogger,
  IconSettingsDollar,
  IconCalendarDollar,
  IconTransactionDollar
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
  id: 'utilities',
  title: 'Utilities',
  type: 'group',
  children: [
    {
      id: 'Users',
      title: 'Users',
      type: 'item',
      url: '/utils/Users',
      icon: icons.IconUser,
      breadcrumbs: false
    },
    {
      id: 'util-activity',
      title: 'Activity',
      type: 'item',
      url: '/utils/transactions',
      icon: icons.IconActivityHeartbeat,
      breadcrumbs: false
    }
  ]
};

export default utilities;
