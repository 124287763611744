import { lazy } from 'react';

// project imports
import MainLayout from '../layout/MainLayout';
import Loadable from '../ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('../views/dashboard/Default')));

// utilities routing
const UserManager = Loadable(lazy(() => import('../views/utilities/Users-manager')));
//const HealthCheck = Loadable(lazy(() => import('ui-component/healthCheck')));
const TransactionHistory = Loadable(lazy(() => import('../views/utilities/TransactionHistory')));
// sample page routing

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <DashboardDefault />
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'default',
          element: <DashboardDefault />
        }
      ]
    },

    {
      path: 'utils',
      children: [
        {
          path: 'transactions',
          element:<TransactionHistory/>
        },
        {
          path: 'users',
          element:<UserManager/>
        }
      ]
    },

  ]
};

export default MainRoutes;
