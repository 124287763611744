/* eslint-disable react/prop-types */
// src/context/UserContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import AuthService from '../services/AuthService';
import { useNavigate } from 'react-router-dom';

const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [userIsLogedIn, setUserIsLogedIn] = useState(false);

  const saveTokens = (token, user) => {
    setUser(user);
    setToken(token);
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));
  };

  useEffect(() => {
    const checkLogin = async () => {
      const res = await AuthService.checkLogin();
      console.log(res);
      if (res.token && res.user) {
        setToken(res.token);
        setUser(res.user);
        setUserIsLogedIn(true);
        // localStorage.setItem('token', res.token);
        // localStorage.setItem('user', JSON.stringify(res.user));
      } else {
     //   setUserIsLogedIn(false);
    //    setToken(null);
     //   setUser(null);
     //   localStorage.removeItem('token');
     //   localStorage.removeItem('user');
        //window.location.href = '/login/login3';
      }
    };
    checkLogin();
  }, []);

  const signIn = async (signInData,recaptcha) => {
    // Implement your sign in logic here, possibly using AuthService
    try {
      const data = await AuthService.signin(signInData,recaptcha);
      console.log(data);
      if (data.token && data.user) {
        saveTokens(data.token, data.user);
        setUserIsLogedIn(true);
        return data;
      } else {
        return data;
      }
    } catch (error) {
      console.error('Error signing in:', error);
      return error;
    }
  };

  const signOut = () => {
    // Implement sign out logic
    AuthService.signout();
    setUser(null);
    setToken(null);
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setUserIsLogedIn(false);
    navigate('/');
  };

  const signUp = async (signUpData) => {
    try {
      const data = await AuthService.signup(signUpData);
      if (data.token && data.user) {
        saveTokens(data.token, data.user);
        setUserIsLogedIn(true);
        return data;
      } else {
        console.log('Error signing up:', data);
        return data;
      }
    } catch (error) {
      console.error('Error signing up:', error);
      return error;
    }
  };

  return <UserContext.Provider value={{ userIsLogedIn, user, token, signIn, signOut, signUp }}>{children}</UserContext.Provider>;
};

export const useUser = () => useContext(UserContext);
