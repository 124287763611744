import { env } from '../env'
export const BASE_URL ="https://bflodevapi.nobleprofit.net" //env.REACT_APP_BACKEND_DOMAIN;
export const API_v2_URL = '/v2/api';
export const API_FULL_URL = BASE_URL + API_v2_URL;




export const authURL = {
  "auth": API_FULL_URL + "/auth",
  "checkAuthentication": API_FULL_URL + "/auth/checkAuthentication",
  "logout": API_FULL_URL + "/auth/logout",
  "register": API_FULL_URL + "/auth/registration",
  "updateuserdata": API_FULL_URL + "/auth/updateuserdata",
  "roles": API_FULL_URL + "/auth/getroles",
  "getuserdata": API_FULL_URL + "/auth/getuserdata",
  "checkusernameemail": API_FULL_URL + "/auth/checkusernameemail",
  "changepassword": API_FULL_URL + '/auth/changepassword',
  "resetpassword": API_FULL_URL + '/auth/resetpassword',
  "getusers": API_FULL_URL + '/auth/getusers',
  "deleteUser": API_FULL_URL + '/auth/deleteuser',
  "updateuser": API_FULL_URL + '/auth/updateuser',
  "confirmRegistration": API_FULL_URL + '/auth/confirmregistration',
  "confirmPassword": API_FULL_URL + '/auth/confirmpassword',
  "changeEmail": API_FULL_URL + '/auth/changeemail',
  "getuser": API_FULL_URL + '/auth/getuser',
  "authenticatewithlinkedin": API_FULL_URL + '/auth/authenticatewithlinkedin',

}


export const fileURL = {
  "downloadfile": API_FULL_URL + "/downloadfile",
  "uploadreportfile": API_FULL_URL + "/uploadreportfile",
  "getprofilephoto": API_FULL_URL + "/getprofilephoto",
  "uploadprofilephoto": API_FULL_URL + "/uploadprofilephoto",
  "uploadcsv": API_FULL_URL + "/uploadcsv",
  "getallcsvFilelist": API_FULL_URL + "/getallcsvFilelist",
  "getcsv": API_FULL_URL + "/getcsv",
}


export const verification = {
  "requestVerification": API_FULL_URL + "/verification/requestverification",
  "getReportComments": API_FULL_URL + "/verification/getReportComments",
  "deleteReportComment": API_FULL_URL + "/verification/deleteReportComment",
  "addReportComment": API_FULL_URL + "/verification/addReportComment",
  "updateReportComment": API_FULL_URL + "/verification/updateReportComment",
  "getVerificationProcessOfReport": API_FULL_URL + "/verification/getVerificationProcessOfReport",
  "updateVerificationProcessOfReport": API_FULL_URL + "/verification/updateVerificationProcessOfReport",
  "addTaskComment": API_FULL_URL + "/verification/addTaskComment",
  "updateTaskComment": API_FULL_URL + "/verification/updateTaskComment",
  "deleteTaskComment": API_FULL_URL + "/verification/deleteTaskComment",
  "getTaskComments": API_FULL_URL + "/verification/getTaskComments",
  // addTaskField updateTaskField  deleteTaskField  getTaskFields
  "addTaskField": API_FULL_URL + "/verification/addTaskField",
  "updateTaskField": API_FULL_URL + "/verification/updateTaskField",
  "deleteTaskField": API_FULL_URL + "/verification/deleteTaskField",
  "getTaskFields": API_FULL_URL + "/verification/getTaskFields",
  "addAttachment": API_FULL_URL + "/verification/addAttachment",
  "removeAttachment": API_FULL_URL + "/verification/removeAttachment",
  "getTasks": API_FULL_URL + "/verification/getTasks",
  "getMyVerificationcations": API_FULL_URL + "/verification/getMyVerificationcations",
  "initiateVerificationRequest": API_FULL_URL + "/verification/initiateVerificationRequest",
  "confirmVerificationRequest": API_FULL_URL + "/verification/confirmVerificationRequest",
  "closeVerificationProcess": API_FULL_URL + "/verification/closeVerificationProcess",
}


export const bfloWallet = {
  "generateWallet": API_FULL_URL + "/bfloWallet/generateWallet",
  "connectBfloWallet": API_FULL_URL + "/bfloWallet/connectBfloWallet",
  "addweb3Wallet": API_FULL_URL + "/bfloWallet/addweb3Wallet",
  "emitTransaction": API_FULL_URL + "/bfloWallet/emitTransaction",
  "contractInteraction": API_FULL_URL + "/bfloWallet/contractInteraction",
  //generateWallet, emitTransaction , getWalletBalance, getWalletTransactions, getWallets
  "getWalletBalance": API_FULL_URL + "/bfloWallet/getWalletBalance",
  "getWalletTransactions": API_FULL_URL + "/bfloWallet/getWalletTransactions",
  "switchNetwork": API_FULL_URL + "/bfloWallet/switchNetwork",
  "getMyTransactions": API_FULL_URL + "/bfloWallet/getMyTransactions",
  "addUpdateTransaction": API_FULL_URL + "/bfloWallet/addUpdateTransaction",
  "getAllTransactions": API_FULL_URL + "/bfloWallet/getAllTransactions",
  "getWallet": API_FULL_URL + "/bfloWallet/getWallet",
  "getMyNFTs": API_FULL_URL + "/bfloWallet/getMyNFTs",
  "getNFT": API_FULL_URL + "/bfloWallet/getNFT",
  "addNFT": API_FULL_URL + "/bfloWallet/addNFT",
  "getfingerprint": API_FULL_URL + "/bfloWallet/getfingerprint",
  "getShareLink": API_FULL_URL + "/bfloWallet/getShareLink",


}


export const dataURL = {
  "industry": API_FULL_URL + "/data/industry",
  "industryAll": API_FULL_URL + "/data/industry/all",
  "goals": API_FULL_URL + "/data/goals",
  "targets": API_FULL_URL + "/data/targets",
  "indicators": API_FULL_URL + "/data/indicators",
  "getAllGoals": API_FULL_URL + "/data/getallgoals",
  "getAllTargets": API_FULL_URL + "/data/getalltargets",
  "getAllIndicators": API_FULL_URL + "/data/getallindicators",
  "getAllGoalTree": API_FULL_URL + "/data/getallgoaltree",
  "getimpacts": API_FULL_URL + "/data/getimpacts",
  "getGICSIndustry": API_FULL_URL + "/data/getgicsindustry",
  "getNACEIndustry": API_FULL_URL + "/data/getnaceindustry",
  "getSASBIndustry": API_FULL_URL + "/data/getsasbindustry",
  "getTRBCIndustry": API_FULL_URL + "/data/gettrbcindustry",
  "getAllIndustriesClassifications": API_FULL_URL + "/data/getallindustriesclassifications",
}

export const entityURL = {
  "entityList": API_FULL_URL + "/entity/entitylist",
  "updateEntity": API_FULL_URL + "/entity/updateentity",
  "getEntity": API_FULL_URL + "/entity/getentity",
  "searchEntity": API_FULL_URL + "/entity/searchentity",
  "allEntities": API_FULL_URL + "/entity/allentities",
  "entityType": API_FULL_URL + "/entity/entitytype",
  "entityStatuses": API_FULL_URL + "/entity/entityStatuses",
  "e2eRelationtypes": API_FULL_URL + "/entity/e2eRelationtypes",
  "r2eRelationtypes": API_FULL_URL + "/entity/r2eRelationtypes",
  "addEntity": API_FULL_URL + "/entity/addentity",
  "joinentity2entity": API_FULL_URL + "/entity/joinentity2entity",
  "joinreport2entity": API_FULL_URL + "/entity/joinreport2entity",
  "joinentity2entitybasedreport": API_FULL_URL + "/entity/entity2entitybasedreport",
  "searchEntityByName": API_FULL_URL + '/entity/searchentitybyname',
  "autocomplete": API_FULL_URL + '/entity/autocomplete',
}

export const reportURL = {
  "getAllReports": API_FULL_URL + "/allreports",
  "getMyReports": API_FULL_URL + "/report/myreports",
  "search": API_FULL_URL + "/reportsearch",
  "searchv2": API_FULL_URL + "/report/searchv2",
  "searchv3": API_FULL_URL + "/report/searchv3",
  "createReport": API_FULL_URL + "/report/create",
  "updateReport": API_FULL_URL + "/report/update",
  "createsdg": API_FULL_URL + "/report/createsdg",
  "createesg": API_FULL_URL + "/report/createesg",
  "getReportsTypes": API_FULL_URL + "/report/reporttypes",
  "getReport": API_FULL_URL + "/report/getreport",
  "linkReport": API_FULL_URL + "/report/linkReport",
  "checkReport": API_FULL_URL + "/report/checkreport",
  "createSDPIReport": API_FULL_URL + "/report/createsdpireport",
  "updateSDPIReport": API_FULL_URL + "/report/updatesdpireport",
  "getMySDPIReports": API_FULL_URL + "/report/getMySDPIReports",
  "deleteSDPIDraftReport": API_FULL_URL + "/report/deleteSDPIDraft",
  "getSDPIReport": API_FULL_URL + "/report/getSDPIReport",

}

export const locationURL = {
  "getlocationtypes": API_FULL_URL + "/location/getlocationtypes",
  "m49countrylist": API_FULL_URL + "/location/getm49countrylist",
  "getcountry": API_FULL_URL + "/location/getcountry",
  "getadministration": API_FULL_URL + "/location/getadministration",
  "getcity": API_FULL_URL + "/location/getcity",
  "addentitylocation": API_FULL_URL + "/location/addentitylocation",
  "addreportlocation": API_FULL_URL + "/location/addreportlocation",
  "addsdglocation": API_FULL_URL + "/location/addsdglocation",
  "addesglocation": API_FULL_URL + "/location/addesglocation",
  "addGeoLocation": API_FULL_URL + "/location/addgeolocation",
  "getGeocoding": API_FULL_URL + "/location/getgeocoding",
}

export const visualisationURL = {
  "getTimeScape": API_FULL_URL + "/visualization/gettimescape",
  "getFingerPrint": API_FULL_URL + "/visualization/searchfingerprint",
  "getFingerPrintAll": API_FULL_URL + "/visualization/getfingerprintgetall",
  "getentitiesgraph": API_FULL_URL + "/visualization/getentitiesgraph",
  "getentitieschord": API_FULL_URL + "/visualization/getentitieschord",
  "getFootprintData": API_FULL_URL + "/visualization/getfootprintdata",
  "getFingerprintData": API_FULL_URL + "/visualization/getfingerprintdata",
  "getFootprintDataV2": API_FULL_URL + "/visualization/getfootprintdatav2",
  "getFingerprintDataMinimal": API_FULL_URL + "/visualization/getfingerprintdataminimal",
  "gettimescapedynamic": API_FULL_URL + "/visualization/gettimescapedynamic",
}

export const blockChain = {
  "addTransaction": API_FULL_URL + "/blockchain/addtransaction",
  "getTransaction": API_FULL_URL + "/blockchain/getTransaction",
  "getMyTransaction": API_FULL_URL + "/blockchain/getmytransaction",
  "searchTransaction": API_FULL_URL + "/blockchain/searchtransaction",
  "getAllTransaction": API_FULL_URL + "/blockchain/getalltransaction",
  "updateTxStatus": API_FULL_URL + "/blockchain/updatetxstatus",
}

export const UISchema = {
  "getESGImpactSchema": API_FULL_URL + "/uischema/getESGImpactSchema",
  "getSPDIReportSchema": API_FULL_URL + "/uischema/getSPDIReportSchema",
}

export const faucetSupply = {
  "getFaucetSupply": API_FULL_URL + "/faucet/getfaucetsupply",
}

export const sertoURL = {
  "createSertoDIDEth": API_FULL_URL + "/serto/createSertoDIDEth",
  "createVerifiableCredential": API_FULL_URL + "/serto/createVerifiableCredential",
  "issueVerifiableCredential": API_FULL_URL + "/serto/issueVerifiableCredential",
  "getVerifiableCredential": API_FULL_URL + "/serto/getVerifiableCredential",
  "getSertoSchema": API_FULL_URL + "/serto/getSertoSchema",
  "issueSocialMediaProfileLinkage": API_FULL_URL + "/serto/issueSocialMediaProfileLinkage",
  "getMyCredentials": API_FULL_URL + "/serto/getmycredentials",
}

export const NFTstorage = {
  "uploadfileweb3storage": API_FULL_URL + "/nftstore/web3storage",
  "uploadfileipfs": API_FULL_URL + "/nftstore/ipfs",
}

export const adminURL = {
  "getalltransactions": API_FULL_URL + "/admin/getalltransactions",
  "getuserlogs": API_FULL_URL + "/admin/getuserlogs",
}



export default { adminURL, sertoURL, faucetSupply, UISchema, BASE_URL, API_v2_URL, API_FULL_URL, authURL, dataURL, reportURL, visualisationURL, fileURL, blockChain }