import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

// routing
import Routes from './routes';

// defaultTheme
import themes from './themes';

// project imports
import NavigationScroll from './layout/NavigationScroll';
import { UserProvider } from './contexts/userContext';

// ==============================|| APP ||============================== //
export const RECAPTCHA_SITE_KEY = '6LcRZh8qAAAAACOUNk6JS_JW9HeCtFWMU3c7-gze';

const App = () => {
  const customization = useSelector((state) => state.customization);

  return (
    <StyledEngineProvider injectFirst>
      <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
        <ThemeProvider theme={themes(customization)}>
          <CssBaseline />
          <UserProvider>
            <NavigationScroll>
              <Routes />
            </NavigationScroll>
          </UserProvider>
        </ThemeProvider>
      </GoogleReCaptchaProvider>
    </StyledEngineProvider>
  );
};

export default App;
