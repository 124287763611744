import { getRequest, postRequest, logOutAction, setAuthTokenAndUser, getHeader } from './httpSetup';
import { authURL } from './apiEndpoints';
import axios from 'axios';

export const signout = async (params) => {
  try {
    const config = getHeader();
    const url = authURL.logout;
    const result = await postRequest(url, params, config);
    console.log(result);
  } catch (err) {
    logOutAction();
    console.log(err);
  } finally {
    logOutAction();
  }
};

export const signin = async (data, recaptchaToken) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'reCAPTCHA-Token': recaptchaToken // Add this line
    }
  };
  const url = authURL.auth;
  const body = JSON.stringify(data);
  let statusCode = '';
  try {
    const res = await axios.post(url, body, config);
    statusCode = res.status;
    if (res.status === 200) {
      setAuthTokenAndUser(res.data.token, res.data.user);
      return { authorized: true, user: res.data.user };
    } else {
      return { authorized: false, statusCode: statusCode };
    }
  } catch (err) {
    console.log({ ...err });
    logOutAction();
    return { authorized: false, isAxiosError: err.isAxiosError, statusCode: statusCode, message: err.message, response: err.response };
  }
};

export const checkLogin = async () => {
  try {
    return getRequest(authURL.checkAuthentication, getHeader());
  } catch (error) {
    console.error('Error checking login:', error);
  }
};


export default { signin, checkLogin, signout, setAuthTokenAndUser, logOutAction };
