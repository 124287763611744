// httpSetup.js
import axios from 'axios';

export const setAuthTokenAndUser = (token, user) => {
  if (token) {
    localStorage.setItem('token', token);
    axios.defaults.headers.common['Authorization'] = token;
  }
  if (user) {
    localStorage.setItem('user', JSON.stringify(user));
  }
};

export const getHeader = () => {
  try {
    const token = localStorage.getItem('token');
    return {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token
      }
    };
  } catch {
    console.log('can not read token@!');
    return {
      headers: {
        'Content-Type': 'application/json'
      }
    };
  }
};

export const logOutAction = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('token');
};

export const chackAndLogout = (response) => {
  if (typeof response?.data?.message !== 'undefined') {
    if (response?.data?.message === 'Invalid Token' || response?.data?.message === 'Access Denied') {
      logOutAction();
    }
  }
};

export const getRequest = async (url, config, checkLogin = true) => {
  let statusCode;
  try {
    const res = await axios.get(url, config);
    statusCode = res.status;
    if (checkLogin) chackAndLogout(res);
    return res.data;
  } catch (err) {
    if (checkLogin) chackAndLogout(err.response);
    return { authorized: false, isAxiosError: err.isAxiosError, statusCode: statusCode, message: err.message, response: err.response };
  }
};

export const postRequest = async (url, data, config, checkLogin = true) => {
  let statusCode;
  try {
    const res = await axios.post(url, data, config);
    statusCode = res.status;
    if (checkLogin) chackAndLogout(res);
    return res.data;
  } catch (err) {
    if (checkLogin) chackAndLogout(err.response);
    return { authorized: false, isAxiosError: err.isAxiosError, statusCode: statusCode, message: err.message, response: err.response };
  }
};

export const putRequest = async (url, data, config, checkLogin = true) => {
  let statusCode;
  try {
    const res = await axios.put(url, data, config);
    statusCode = res.status;
    if (checkLogin) chackAndLogout(res);
    return res.data;
  } catch (err) {
    if (checkLogin) chackAndLogout(err.response);
    return { authorized: false, isAxiosError: err.isAxiosError, statusCode: statusCode, message: err.message, response: err.response };
  }
};

export const deleteRequest = async (url, config, checkLogin = true) => {
  let statusCode;
  try {
    const res = await axios.delete(url, config);
    statusCode = res.status;
    if (checkLogin) chackAndLogout(res);
    return res.data;
  } catch (err) {
    if (checkLogin) chackAndLogout(err.response);
    return { authorized: false, isAxiosError: err.isAxiosError, statusCode: statusCode, message: err.message, response: err.response };
  }
};

export default { getRequest, postRequest, putRequest, deleteRequest, logOutAction, setAuthTokenAndUser, getHeader };
