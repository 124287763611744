import React from 'react';
import { useTheme } from '@mui/material/styles';

// Import the logo images
import logoDark from '../assets/images/logo-dark.png';
import logo from '../assets/images/logo.png';

// ==============================|| LOGO IMAGE ||============================== //

const Logo = () => {
  const theme = useTheme();

  // Example: Use the dark logo for dark mode and the regular logo for light mode
  const logoSrc = theme.palette.mode === 'dark' ? logoDark : logo;

  return (
    <img src={logoSrc} alt="Logo" width="100" />
  );
};

export default Logo;
